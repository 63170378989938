import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Swiper from 'swiper';

import "../../../node_modules/swiper/dist/css/swiper.min.css";
import "./style.scss";

export default class FunSwiper extends Component {

  static propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    index: PropTypes.number,
    effect: PropTypes.string,
    itemClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    showPagination: PropTypes.bool,
    loop: PropTypes.bool,
  }

  static defaultProps = {
    index: 0,
    showPagination: false,
    loop: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      current: this.props.index,
      showBars: true,
      swiping: false,
      effect: this.props.effect || 'coverflow',
      showPagination: this.props.showPagination,
      loop: this.props.loop,
    };
  }

  componentDidMount() {
    this.initSwiper();
  }

  initSwiper() {
    const { effect, current, showPagination, loop } = this.state;
    if (effect === 'coverflow') {
      this.swiper = new Swiper('.swiper-container1', {
        pagination: '.swiper-pagination',
        effect: 'coverflow',
        slidesPerView: 'auto',
        grabCursor: true,
        centeredSlides: true,
        initialSlide: this.props.index,
        coverflowEffect: {
          rotate: 0,
          stretch: 100,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        loop: true,
      });
    } else {
      this.swiper = new Swiper('.swiper-container1', {
        observer:true,
        observeParents:true, 
        pagination: showPagination ? {
          el: '.swiper-pagination',
          clickable: true,
        } : {},
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // direction: 'vertical',
        loop:false,
        initialSlide: current,
        passiveListeners: false,
      });
    }

    this.swiper.on('click', e => {
      const { onSwiperItemClick } = this.props;
      if (onSwiperItemClick) {
        onSwiperItemClick(e.target);
      }
    });
    this.swiper.on('slideChangeTransitionStart', () => this.onChangeStart());
    this.swiper.on('slideChangeTransitionEnd', () => this.onChange());
  }

  componentWillUnmount() {
    this.swiper.off('click');
    this.swiper.off('slideChangeTransitionStart');
    this.swiper.off('slideChangeTransitionEnd');
  }

  onChangeStart() {
    this.setState({
      swiping: true
    })
  }

  onChange() {
    const current = this.swiper.realIndex;
    this.setState({
      current: current,
    });
  }

  render() {
    const { className } = this.props;
    return (
      <div className={classNames("fun-swiper", className)}>
        <div className="swiper-container1">
          <div className="swiper-wrapper">
            {this.props.children}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    );
  }
}