import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './index.scss';
import PropTypes from 'prop-types';
import FunIcon from '../fun-icon';
import FunDynamicCore from '../fun-dynamic-core';
import FunInnerScroll from '../fun-inner-scroll';

/**
 *
 * 使用方法
 *
 * <FunMask ref={this.funMaskRef}></FunMask>
 *
 * // 显示 隐藏
 * this.funMaskRef.current.show();
 * this.funMaskRef.current.hide();
 *
 * 注：无法使用props控制mask 已经被废弃 否则会导致重复渲染
 *
 */
export default class FunModal extends FunDynamicCore {
  static propTypes = {
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    isShow: PropTypes.bool,
    onConfirm: PropTypes.func,
    hasConfirm: PropTypes.bool,
    title: PropTypes.string,
    unmountOnExit: PropTypes.bool,
    wrapperStyle: PropTypes.any,
    headerStyle: PropTypes.any,
    height: PropTypes.number,
    isScroll: PropTypes.bool,
    isStop: PropTypes.bool,
    isHideImmediatelyWhenConfirm: PropTypes.bool,
  };

  static defaultProps = {
    isStop: true,
    hasConfirm: false,
    unmountOnExit: true,
    isShow: false,
    isAutoDispose: false,
    isScroll: false,
    isHideImmediatelyWhenConfirm: true,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  show() {
    const rootEl = document.documentElement;
    const bodyEl = document.body;
    this.position = window.getComputedStyle(bodyEl).position;
    this.scrollTop = rootEl.scrollTop || bodyEl.scrollTop;
    bodyEl.style.position = 'fixed';
    bodyEl.style.width = '100%';
    bodyEl.style.top = -this.scrollTop + 'px';
    super.show();
  }

  hide() {
    document.body.style.position = this.position;
    document.body.style.top = '';
    window.scrollTo(0, this.scrollTop);
    super.hide();
  }

  onMaskHide() {
    super.hide();
  }

  onClickClose() {
    this.hide();
  }

  onClickConfirm() {
    this.props.isHideImmediatelyWhenConfirm && this.hide();
    FunDynamicCore.doCall(this.props.onConfirm);
  }

  componentDidMount() {
    super.componentDidMount();
    let container = this.containerRef.current;

    if (!this.props.isScroll && this.props.isStop) {
      container.addEventListener('touchmove', function (e) {
        e.preventDefault();
      });
    }
  }

  onTouchMoveHeader(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const { isShow } = this.state;
    const { headerStyle, wrapperStyle, isScroll } = this.props;
    const scrollProps = {
      ...this.props,
      wrapperStyle: { ...wrapperStyle, height: '100%' },
    };

    return ReactDOM.createPortal(
      <div ref={this.containerRef}>
        <CSSTransition
          in={isShow}
          timeout={200}
          classNames="fun-modal-container"
          unmountOnExit={this.props.unmountOnExit}
          onEnter={this.onEnter.bind(this)}
          onEntered={this.onEntered.bind(this)}
          onExit={this.onExit.bind(this)}
          onExited={this.onExited.bind(this)}>
          <div className="fun-modal-container">
            <div className="fun-modal" style={wrapperStyle} _fun-modal="true">
              <div className={'fun-modal-header'} style={headerStyle} onTouchMove={this.onTouchMoveHeader.bind(this)}>
                <div className="left" onClick={this.onClickClose.bind(this)}>
                  {/* <FunIcon icon="icon-close" iconStyle={{ fontSize: '30px' }} /> */}
                  <span style={{ fontSize: '16px', color: 'rgba(255, 208, 163, 1)', fontWeight: 400 }}>取消</span>
                </div>

                <div className="fun-modal-title">{this.props.title}</div>

                {this.props.hasConfirm ? (
                  <div className={'icon-con right'} onClick={this.onClickConfirm.bind(this)}>
                    {/* <FunIcon icon="icon-select" iconStyle={{ fontSize: '30px' }} /> */}
                    <span style={{ fontSize: '16px', color: 'rgba(255, 208, 163, 1)', fontWeight: 700 }}>确定</span>

                  </div>
                ) : (
                  ''
                )}

                <div className="line-top" />
              </div>

              {!isScroll ? (
                <div className={'fun-modal-wrapper'}>{this.props.children}</div>
              ) : (
                <FunInnerScroll className={'fun-modal-wrapper'} {...scrollProps}>
                  {this.props.children}
                </FunInnerScroll>
              )}
            </div>
            <div
              className="fd-back"
              onClick={this.onClickClose.bind(this)}
              onTouchMove={this.onTouchMoveHeader.bind(this)}>
              {' '}
            </div>
          </div>
        </CSSTransition>
      </div>,
      this.el,
    );
  }
}
