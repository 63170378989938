import React, { useEffect, useState } from "react";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import ReactLoading from "react-loading";
import "./index.scss";

import FunDialog from "@/components/fun-dialog";
import FunSwiper from "@/components/fun-swiper";

import Image from "@/components/image";
import SwiperItem from "./swiper-item";

import simulationBg from "../../public/images/icon-simulation.png";

import growUpImg from "../../public/images/icon-grow-up.png";
import simulatorAgainImg from "../../public/images/icon-simulator-again.png";
import hongqiImg from "../../public/images/icon-hongqi.png";
import GenerateItem from "../simulation-experience/generate-item";
import model from "./model";
import { historyList } from "./mock";

export default function SimulationExperience(props) {
  const [experiences, setExperiences] = useState([]);
  const [isSummarize, setIsSummarize] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [summary, setSummary] = useState("");
  const [swiperList, setSwiperList] = useState([]);

  const [showPop, setShowPop] = useState(false);
  const [popItem, setPopItem] = useState({ event: "", special_pic: "" });
  const [loading, setLoading] = useState(true);
  const chatListRef = React.createRef(null);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const age = query.get("age");
    const area = query.get("area");
    const habit = query.get("habit");
    const nologin = query.get("no");

    model.generate({ age, area, habit: area, nologin }).then((res) => {
      if (res) {
        const _list = [];
        const _event = res.event || [];

        const firstItem = _event.shift();
        _list.push(firstItem);
        setExperiences(_list);
        setEventList(_event);
        setSummary(res.summary || "");

        if (firstItem.is_special === 1) {
          const tempSwiperList = [];
          tempSwiperList.push(firstItem.special_pic);
          setSwiperList([...tempSwiperList]);
          setPopItem(firstItem);
          setShowPop(true);
        }
        setLoading(false);
      }
    });
    // if (res) {
    //   const _list = [];
    //   const _event = res.event || [];
    //   const specialList = _event.filter((item) => item.is_special === 1);

    //   _list.push(_event.shift());
    //   setExperiences(_list);
    //   setEventList(_event);
    //   setSummary(res.summary || "");

    //   const _swiperList = specialList.map((item) => item.special_pic);
    //   setSwiperList(_swiperList);
    // }
  }, []);

  const generate = () => {
    if (eventList.length > 0) {
      const _list = experiences;
      const addItem = eventList.shift();
      _list.push(addItem);
      setExperiences([..._list]);
      if (addItem && addItem.is_special === 1) {
        historyList.forEach((item) => {
          if (Number(addItem.year) === item.year) {
            addItem.event = item.event;
          }
        });
        setShowPop(true);
        setPopItem(addItem);
        const tempSwiperList = swiperList;
        tempSwiperList.unshift({
          special_pic: addItem.special_pic,
          event: addItem.event,
        });
        setSwiperList([...tempSwiperList]);
      }
    } else {
      setIsSummarize(true);
    }
  };

  // 监听聊天数据的变化，改变聊天容器元素的 scrollTop 值让页面滚到最底部
  useEffect(() => {
    if (isSummarize || loading) return;
    const current = chatListRef.current;
    //scrollHeight是页面的高度
    current.scrollTop = current.scrollHeight;
  }, [experiences]);

  const simulatorAgain = () => {
    setIsSummarize(false);
    props.history.replace("./ai-study");
  };

  const Summarize = () => {
    return (
      <>
        <div className="summarize-container">
          <div className="summarize">{summary}</div>
        </div>
        <Image
          className="simulator-again"
          src={simulatorAgainImg}
          onClick={simulatorAgain}
        />
      </>
    );
  };
  const swiperClick = (item) => {
    setShowPop(true);
    setPopItem(item);
  };

  return (
    <div className="simulation-experience-container">
      <FunLayout
        type={EnLayoutType.fixedLayout}
        className={"main-content-p"}
        isAdaptorKeyboard={true}
      >
        <Image className="bg-img" scale={750 / 1622} src={simulationBg} />

        <div className="simulation-content">
          {loading ? (
            <div className="simulation-loading">
              <ReactLoading
                type="bars"
                color="#CBB486"
                width={"10%"}
                height={50}
              />
              <div>人生模拟中......</div>
            </div>
          ) : (
            <>
              <div style={{ height: "30px" }}></div>

              {swiperList.length > 0 && (
                <FunSwiper
                  className="swiper-container"
                  effect={"normal"}
                  showPagination={true}
                  loop={swiperList.length >= 2}
                  // onSwiperItemClick={value => this.onSwiperItemClick(value)}
                >
                  {swiperList.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        <div
                          className="swiper-zoom-container"
                          onClick={() => {
                            swiperClick(item);
                          }}
                        >
                          <SwiperItem url={item.special_pic} />
                        </div>
                      </div>
                    );
                  })}
                </FunSwiper>
              )}

              <div className="title-container">
                <Image className="hongqi-img" src={hongqiImg} />
                <span className="title">
                  {isSummarize ? "人生总结" : "经历事件纪要"}
                </span>
              </div>

              {isSummarize ? (
                <Summarize />
              ) : (
                <>
                  <div
                    className="experience"
                    style={{ overflow: "scroll", scrollBehavior: "smooth" }}
                    ref={chatListRef}
                  >
                    {experiences.map((item, index) => {
                      return (
                        <GenerateItem
                          item={item}
                          index={index}
                          current={experiences}
                        />
                      );
                    })}
                  </div>
                  <Image
                    className="grow-up"
                    src={growUpImg}
                    onClick={generate}
                  />
                </>
              )}
              <div style={{ height: "50px" }}></div>
            </>
          )}
        </div>
        <FunDialog
          isShow={showPop}
          customShow
          noTitle
          noCancel
          toggle={false}
          backgroundColor={"transparent"}
          children={
            <div className="pop">
              <SwiperItem
                className="pop-img-content"
                url={popItem.special_pic}
              />
              <div className="pop-content-container">
                <span className="pop-content">{popItem.event}</span>
              </div>
            </div>
          }
          onConfirm={() => setShowPop(false)}
        />
      </FunLayout>
    </div>
  );
}
