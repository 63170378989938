import './index.scss';
export default function GenerateItem(props) {
  const isMaxValueInArray = (value, array) => array.length === 0 || array.length - 1 === value;

  const { item: { age, event }, index, current } = props;
  const isCurrent = isMaxValueInArray(index, current);
  const ageEnd = parseInt(age, 10) + 2;
  const isFirst = index === 0;
  const isNotFirst = index > 0;

  const circleClass = isCurrent ? 'circle solid-circle' : 'circle';
  const lineClass = isCurrent  ? '' : 'line';

  return (
    <div className="generate-item-container">
      <div>
        {isFirst && <div className={circleClass} />}
        {isFirst && <div className={lineClass} />}
        {isNotFirst && <div className={circleClass} />}
        {isNotFirst && <div className={lineClass} />}
      </div>
      <div>
        <div className="age">{`${age}~${ageEnd}岁`}</div>
        <div className="experience">{event}</div>
      </div>
    </div>
  );
}
