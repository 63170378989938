// AudioPlayer.js
import { MusicContext } from "@/ MusicContext";
import { useRef, useContext, useEffect, useState } from "react";
import Image from "@/components/image";

import music from "../../public/audio/music.mp3";
import playImg from "../../public/images/icon-play-music.png";
import stopImg from "../../public/images/icon-stop-music.png";

import "./index.scss";

const AudioPlayer = () => {
  const { isPlaying, setIsPlaying } = useContext(MusicContext);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  const [hasInteracted, setHasInteracted] = useState(false);
  const firstRef = useRef(true);

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
      document.removeEventListener("click", handleInteraction);
    };
    document.addEventListener("click", handleInteraction);
  }, []);

  useEffect(() => {
    if (hasInteracted) {
      if (isPlaying) {
        audioRef.current?.play();
      } else {
        audioRef.current?.pause();
      }
      if (firstRef.current) {
        firstRef.current = false;
        setIsPlaying(true);
      }
    }
  }, [hasInteracted, isPlaying]);

  const playOrStopMusic = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="audio-player-container">
      <div onClick={playOrStopMusic}>
        <Image
          className="music-play-img"
          scale={1}
          src={isPlaying ? playImg : stopImg}
        />
      </div>
      <audio ref={audioRef} src={music} loop autoPlay></audio>
    </div>
  );
};

export default AudioPlayer;
